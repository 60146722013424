// src/components/shiftManagement/ShiftFilters.js

import React, {useCallback, useEffect, useRef, useState} from 'react';
import axios from "axios";

import DateFilter from './DateFilter';
import styles from './ShiftFilters.module.css';
import StoreFilter from "./StoreFilter";

const ShiftFilters = ({ filter, setFilter, setShifts, setIsFetching, handleShowLottie, handleHideLottie }) => {

    const [stores, setStores] = useState([]);
    const [users, setUsers] = useState([]);
    const timer = useRef(null);

    useEffect(() => {
        console.log("lol6");
        const fetchStores = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get('https://rezervacny-system-a1350e3ff1d6.herokuapp.com/stores', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setStores(response.data);
            } catch (error) {
                console.error('Error fetching stores:', error);
            }
        };
        fetchStores().catch(console.error);
    }, []);
    
    const constructQueryString = (filters) => {
        console.log("lol5");
        let queryString = "?";

        // Check each filter and append to queryString if not default value
        console.log("filters.dateRange: ", filters.dateRange)
        if (filters.dateRange !== 'tyzden') {
            // Depending on your backend, you might need to format the date or handle ranges differently
            queryString += `dateRange=${filters.dateRange}&`;
        }

        if (filters.date) {
            let formattedDate;
            // Check if filters.date is an instance of Date
            if (filters.date instanceof Date) {
                formattedDate = filters.date.toISOString().split('T')[0];
            } else {
                // Assuming filters.date is a string in YYYY-MM-DD format
                formattedDate = filters.date;
            }
            queryString += `date=${formattedDate}&`;
        }

        if (filters.store !== 'all') {
            queryString += `store=${filters.store}&`;
        }

        if (filters.timeOfDay !== 'all') {
            queryString += `timeOfDay=${filters.timeOfDay}&`;
        }

        if (filters.availability !== 'all') {
            queryString += `availability=${filters.availability}&`;
        }

        if (filters.worker !== 'all') {
            queryString += `worker=${filters.worker}&`;
        }

        // Remove the last '&' or '?' from the queryString if it's the last character
        if (queryString.endsWith('&') || queryString.endsWith('?')) {
            queryString = queryString.slice(0, -1);
        }

        return queryString;
    };


    useEffect(() => {
        console.log("lol4");
        const fetchUsers = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get('https://rezervacny-system-a1350e3ff1d6.herokuapp.com/users/role/user', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers().catch(console.error);
    }, []);

    const fetchShiftsWithDebounce = useCallback(() => {
        console.log("lol3");
        if (timer.current) clearTimeout(timer.current);
        setIsFetching(true);
        handleShowLottie();

        timer.current = setTimeout(async () => {
            setIsFetching(false);
            handleHideLottie();
            const token = localStorage.getItem('token');
            const queryString = constructQueryString(filter);
            console.log("queryString: ", queryString)
            try {
                const response = await axios.get(`https://rezervacny-system-a1350e3ff1d6.herokuapp.com/shifts${queryString}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                console.log("response: ", response.data);

                if (response.status === 200) {
                    const formattedShifts = response.data.map(shift => {
                        const formatSlovakDate = (date) => {
                            const parts = date.split('-');
                            return `${parts[2]}.${parts[1]}.${parts[0]}`;
                        };

                        return {
                            id: shift.shiftId,
                            storeName: shift.storeName,
                            imageUrl: shift.imageUrl,
                            date: formatSlovakDate(shift.shiftDate),
                            startTime: shift.startTime,
                            endTime: shift.endTime,
                            isBooked: shift.isBooked,
                            bookedBy: shift.bookedByUserName,
                            isExpanded: false,
                            isEditing: false,
                        };
                    });
                    setShifts(formattedShifts);
                } else if (response.status === 404) {
                    // Handle the case when no shifts are found
                    setShifts([]); // Clear the shifts
                    console.log("here");
                    // Optionally, set a state to show a message in the UI indicating no shifts were found
                }
            } catch (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 404) {
                        // Handle no shifts found
                        setShifts([]);
                        // Optionally, show a message in the UI
                    } else {
                        // Handle other possible HTTP errors
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log("no, here");
            }
        }, 600); // Debounce interval

    }, [filter, handleShowLottie, handleHideLottie, setShifts, setIsFetching]); // ESLint: React Hook useCallback has missing dependencies: 'setIsFetching' and 'setShifts'. Either include them or remove the dependency array. If 'setIsFetching' changes too often, find the parent component that defines it and wrap that definition in useCallback.(react-hooks/exhaustive-deps)

    useEffect(() => {
        console.log("lol2");
        fetchShiftsWithDebounce();
    }, [fetchShiftsWithDebounce]);

    const handleFilterChange = (e) => {
        console.log("lol1");
        const { name, value } = e.target;
        if (name === 'date') {
            // Ensure the date string from the input is converted back to a Date object
            setFilter(prev => ({ ...prev, [name]: new Date(value) }));
        } else {
            setFilter(prev => ({ ...prev, [name]: value }));
        }
    };

    return (
        <div className={styles.filtersContainer}>
            <DateFilter
                setFilter={setFilter}
            />

            <StoreFilter stores={stores} filter={filter} setFilter={setFilter} />

            {/* Time of Day Selector */}
            <div className={styles.filterItem}>
                <label htmlFor="timeOfDay" className={styles.filterLabel}>Čas dňa</label>
                <select
                    id="timeOfDay"
                    name="timeOfDay"
                    className={styles.filterSelect}
                    value={filter.timeOfDay}
                    onChange={handleFilterChange}
                >
                    <option value="all">Všetky</option>
                    <option value="morning">Ranna</option>
                    <option value="afternoon">Poobedná</option>
                    <option value="other">Iná</option>
                </select>
            </div>

            {/* Availability Selector */}
            <div className={styles.filterItem}>
                <label htmlFor="availability" className={styles.filterLabel}>Dostupnosť</label>
                <select
                    id="availability"
                    name="availability"
                    className={styles.filterSelect}
                    value={filter.availability}
                    onChange={handleFilterChange}
                >
                    <option value="all">All</option>
                    <option value="free">Free</option>
                    <option value="booked">Booked</option>
                </select>
            </div>

            {/* Worker Selector */}
            <div className={styles.filterItem}>
                <label htmlFor="worker" className={styles.filterLabel}>Brigadník</label>
                <select
                    id="worker"
                    name="worker"
                    className={styles.filterSelect}
                    value={filter.worker}
                    onChange={handleFilterChange}
                >
                    <option value="all">All Workers</option>
                    {users.map(user => (
                        <option key={user.userId} value={user.userId}>
                            {user.firstName} {user.lastName}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default ShiftFilters;

