/*
*  src/App.tsx
 */

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/authentication/Login';
import Dashboard from './components/dashboard/Dashboard';
import './App.module.css';
import UserManagement from "./components/userManagement/UserManagement";
import Locations from "./components/locationsManagement/Locations";
import ShiftManagement from "./components/shiftManagement/ShiftManagement";
import AttendanceManagement from './components/attendanceManagement/AttendanceManagement';
import Chat from './components/chat/Chat';
import Notifications from './components/notifications/Notifications';
import Settings from './components/settings/Settings';
import Account from './components/account/Account';
import Events from './components/events/Events';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';

interface User {
    firstName: string;
    userId: number;
}

const App = () => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const payload = JSON.parse(atob(token.split('.')[1]));
                const userFromToken = {
                    firstName: payload.email,
                    userId: payload.userId
                };
                setUser(userFromToken);
            } catch (error) {
                console.error("Failed to parse token", error);
            }
        }
    }, []);

    const extractUserIdFromToken = (token: string): number => {
        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            return payload.userId;
        } catch (error) {
            console.error("Failed to parse token for userId", error);
            return -1;
        }
    };

    const handleLoginSuccess = (userData: User, token: string) => {
        localStorage.setItem('token', token);
        setUser({
            firstName: userData.firstName,
            userId: extractUserIdFromToken(token)
        });
    };


    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Navigate to={user ? "/dashboard" : "/login"} replace />} />
                    <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
                    <Route path="/dashboard" element={user ? <Dashboard user={user} /> : <Navigate to="/login" replace />} />
                    <Route path="/user-management" element={user ? <UserManagement currentUser={user} /> : <Navigate to="/login" replace />} />
                    <Route path="/locations" element={user ? <Locations /> : <Navigate to="/login" replace />} />
                    <Route path="/shifts" element={user ? <ShiftManagement /> : <Navigate to="/login" replace />} />
                    <Route path="/attendance" element={user ? <AttendanceManagement /> : <Navigate to="/login" replace />} />
                    <Route path="/chat" element={user ? <Chat /> : <Navigate to="/login" replace />} />
                    <Route path="/notifications" element={user ? <Notifications /> : <Navigate to="/login" replace />} />
                    <Route path="/settings" element={user ? <Settings /> : <Navigate to="/login" replace />} />
                    <Route path="/account" element={user ? <Account /> : <Navigate to="/login" replace />} />
                    <Route path="/events" element={user ? <Events /> : <Navigate to="/login" replace />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;

