import React from 'react';
import styles from './ConfirmationDialog.module.css'; // Ensure this points to the updated CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

const ConfirmationDialog = ({ isOpen, onClose, onConfirm, message }) => {
    if (!isOpen) return null;

    const handleBackdropClick = () => {
        // Close the dialog when the backdrop is clicked
        onClose();
    };

    const handleDialogClick = (e) => {
        // Stop the click event from propagating to the backdrop
        e.stopPropagation();
    };

    return (
        <div className={styles.backdrop} aria-modal="true" role="dialog" onClick={handleBackdropClick}>
            <div className={styles.dialog} onClick={handleDialogClick}>
                <FontAwesomeIcon icon={faQuestionCircle} size="2x" className={styles.icon}/>
                <p className={styles.message}>{message}</p>
                <div className={styles.buttonContainer}>
                    <button onClick={onConfirm} className={styles.confirmButton}>
                        <FontAwesomeIcon icon={faCheck}/> Áno
                    </button>
                    <button onClick={onClose} className={styles.cancelButton}>
                        <FontAwesomeIcon icon={faTimes}/> Nie
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationDialog;

