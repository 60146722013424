/*
*
* UserDetails.js is a component responsible for displaying and editing user details within a user management system.
* It allows viewing details such as name, surname, email, phone number, and role. When in edit mode, it permits changing these details, including IBAN and password.
* The component interacts with a backend server hosted at https://rezervacny-system-a1350e3ff1d6.herokuapp.com to fetch, update, and delete user information.
* It incorporates a confirmatory step before user deletion to ensure accidental deletions are prevented.
*
 */
import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faTrash, faTimes, faCheck} from '@fortawesome/free-solid-svg-icons';

import styles from './UserDetails.module.css';

const UserDetails = ({ user, onEdit, onSave, onCancel, onDelete, isEditing }) => {
    const [userDetails, setUserDetails] = useState(user); // Initialize with user prop
    const userId = user.userId;


    const fetchUserDetails = useCallback(async () => {
        console.log("userId: ", userId)
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token not found");
            return;
        }
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        try {
            const response = await axios.get(`https://rezervacny-system-a1350e3ff1d6.herokuapp.com/users/${userId}`, { headers });
            setUserDetails(currentDetails => ({ ...currentDetails, ...response.data }));
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    }, [userId]); // UserDetails dependency removed

    useEffect(() => {
        // Initialize userDetails with the user prop whenever it changes
        setUserDetails(user);
        if (isEditing) {
            // Optionally fetch detailed information (e.g., IBAN, password) when editing
            fetchUserDetails().catch(console.error); // Promise returned from fetchUserDetails is ignored
        }
    }, [user, isEditing, fetchUserDetails]);


    const handleEdit = () => {
        onEdit();
        fetchUserDetails().catch(console.error); // This should fetch IBAN and password
    };


    const handleSave = async () => {
        onSave(userDetails); // Assuming onSave correctly handles the submission logic
    };

    const handleCancel = () => {
        onCancel(); // Notify UserManagement to cancel editing
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails(prevDetails => ({ ...prevDetails, [name]: value }));
    };

    const translateRole = (role) => {
        switch(role) {
            case 'owner':
                return 'Majiteľ';
            case 'store':
                return 'Obchod';
            case 'user':
                return 'Brigádnik';
            case 'admin':
                return 'Manažér';
            default:
                return role; // Or any default you see fit
        }
    };

    return (
        <div className={styles.userDetailsContainer}>
            {isEditing ? (
                // Editing view
                <>
                    <div className={styles.userTopRow}>
                        <input
                            type="text"
                            name="firstName"
                            placeholder="Meno"
                            value={userDetails.firstName || ""}
                            onChange={handleChange}
                            className={styles.userDetailInput}
                        />
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Priezvisko"
                            value={userDetails.lastName || ""}
                            onChange={handleChange}
                            className={styles.userDetailInput}
                        />
                    </div>
                    <div className={styles.userContact}>
                        <input
                            type="email"
                            name="email"
                            placeholder="E-mail"
                            value={userDetails.email || ""}
                            onChange={handleChange}
                            className={styles.userDetailInput}
                        />
                        <input
                            type="text"
                            name="phoneNumber"
                            placeholder="Telefónne číslo"
                            value={userDetails.phoneNumber || ""}
                            onChange={handleChange}
                            className={styles.userDetailInput}
                        />
                    </div>
                    <div className={styles.userRoleActions}>
                        <input
                            type="text"
                            name="role"
                            placeholder="Rola"
                            value={userDetails.role || ""}
                            onChange={handleChange}
                            className={styles.userDetailInput}
                        />
                        <div className={styles.actionButtons}>
                            <button onClick={handleCancel} className={styles.cancelButton}>
                                <FontAwesomeIcon icon={faTimes}/> Zrušiť
                            </button>
                            <button onClick={handleSave} className={styles.saveButton}>
                                <FontAwesomeIcon icon={faCheck}/> Uložiť
                            </button>
                        </div>
                    </div>
                    <div className={styles.userBottomRow}>
                        <input
                            type="text"
                            id="iban"
                            name="iban"
                            placeholder="IBAN"
                            value={userDetails.iban || ""}
                            onChange={handleChange}
                            className={styles.userDetailInput}
                            autoComplete="new-password"
                        />
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Heslo"
                            value={userDetails.password || ""}
                            onChange={handleChange}
                            className={styles.userDetailInput}
                            autoComplete="new-password"
                        />
                    </div>
                </>
            ) : (
                // View state
                <>
                    <div className={styles.userTopRow}>
                        <span className={styles.userNameText}>{userDetails.firstName}</span>
                        <span>{userDetails.lastName}</span>
                    </div>
                    <div className={styles.userContact}>
                        <span>{userDetails.email}</span>
                        <span>{userDetails.phoneNumber}</span>
                    </div>
                    <div className={styles.userRoleActions}>
                        <span>{translateRole(userDetails.role)}</span>
                        <div className={styles.actionButtons}>
                            <button onClick={handleEdit} className={styles.editButton}>
                                <FontAwesomeIcon icon={faEdit}/> Upraviť
                            </button>
                            <button onClick={() => onDelete(userId)} className={styles.deleteButton}>
                                <FontAwesomeIcon icon={faTrash}/> Odstrániť
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );

}

export default UserDetails;



