/**
 * UserManagement.js
 *
 * UserManagement component is the main interface for administering user accounts.
 * It allows for viewing and editing user details, adding new users, and deleting existing ones.
 * The component fetches all users upon mounting and provides an interface with a list of users,
 * displaying detailed information for the selected user. Users with appropriate permissions
 * can add a new user or edit details of existing users, including changing admin levels.
 * User addition and editing are facilitated through separate form components. The highest-level
 * admin cannot be deleted to preserve system integrity.
 *
 * Props:
 *  - currentUser: Object containing information about the currently logged-in user.
 *
 * State:
 *  - users: Array of user objects fetched from the server.
 *  - selectedUserId: ID of the currently selected user.
 *  - editMode: Boolean indicating if the edit form for selected user should be displayed.
 *  - showAddUserForm: Boolean controlling the visibility of the AddUserForm.
 *  - isCurrentUserEditMode: Boolean to toggle edit mode specifically for the current user.
 *
 * Author: Oleksandr Molodchyk
 * Years: 2021-2024
 */

import React, {useEffect, useState} from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes, faPlus} from '@fortawesome/free-solid-svg-icons';

import Header from '../common/Header';
import NavigationBar from '../common/NavigationBar';
import CurrentUserDetails from "./CurrentUserDetails";
import AddUserForm from './AddUserForm';
import UserDetails from "./UserDetails";
import ConfirmationDialog from '../common/ConfirmationDialog';

import '../../index.css';
import styles from './UserManagement.module.css';
import dashStyles from '../dashboard/Dashboard.module.css';

const UserManagement = ({ currentUser }) => {
    const [users, setUsers] = useState([]);
    const [setEditMode] = useState(false);
    const [showAddUserForm, setShowAddUserForm] = useState(false);
    const [editUserId, setEditUserId] = useState(null); // Tracks which user is being edited

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("Token not found");
                return;
            }
            try {
                const response = await axios.get(`https://rezervacny-system-a1350e3ff1d6.herokuapp.com/users`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                console.log('Fetched users:', response.data); // response.data has all the fetched users
                setUsers(response.data);
            } catch (error) {
                console.error("Failed to fetch users:", error);
            }
        };
        fetchUsers();
    }, []);

    const handleUserEdit = async (userId, updatedUser) => {
        const token = localStorage.getItem("token");
        // Check for the token and set headers
        const headers = { Authorization: `Bearer ${token}` };

        try {
            console.log("userId for saving: ", userId)
            console.log("updatedUser: ", updatedUser)
            const response = await axios.put(`https://rezervacny-system-a1350e3ff1d6.herokuapp.com/users/${userId}`, updatedUser, { headers });
            if (response.status === 200) {
                setUsers(users.map((user) => (user.userId === userId ? updatedUser : user)));
                setEditUserId(null); // Exit editing mode
            } else {
                console.error('User update was unsuccessful');
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const startEditing = (userId) => {
        setEditUserId(userId);
    };

    const cancelEditing = () => {
        setEditUserId(null);
    };

    const handleAddUser = (newUser) => {
        setUsers([...users, newUser]);
        setShowAddUserForm(false);
    };

    const requestDeleteUser = (userId) => {
        setUserIdToDelete(userId);
        setShowConfirmationDialog(true);
    };

    const handleConfirmDelete = async () => {
        if (!userIdToDelete) return;
        // Proceed with deletion logic
        await handleUserDelete(userIdToDelete);
        setShowConfirmationDialog(false);
        setUserIdToDelete(null); // Reset the state
    };

    const handleCancelDelete = () => {
        setShowConfirmationDialog(false);
        setUserIdToDelete(null); // Reset the state
    };

    const handleUserDelete = async (userId) => {
        console.log("userId: ", userId)
        const token = localStorage.getItem("token"); // Retrieve the JWT token from local storage
        if (!token) {
            console.error("Token not found");
            return;
        }

        const headers = {
            Authorization: `Bearer ${token}`,
        };

        try {
            await axios.delete(`https://rezervacny-system-a1350e3ff1d6.herokuapp.com/users/${userId}`, { headers });
            setUsers(currentUsers => currentUsers.filter(user => user.userId !== userId));
        } catch (error) {
            console.error('Error deleting user:', error);
            // Handle error (e.g., show a message to the user)
        }
    };

    return (
        <div className={dashStyles.dashboardContainer}>
            <Header />
            <NavigationBar />
            <main className={dashStyles.dashboardMain}>
                {/* Display details of the currently logged-in user */}
                {currentUser && (
                    <CurrentUserDetails
                        userId={currentUser.userId}
                        onEdit={() => setEditMode(true)}
                    />
                )}

                {/* Button to toggle the visibility of the AddUserForm */}
                <div className={styles.addUserButtonContainer}>
                    <button
                        className="toggleButton"
                        onClick={() => setShowAddUserForm(!showAddUserForm)}
                    >
                        <FontAwesomeIcon
                            icon={showAddUserForm ? faTimes : faPlus}/> {showAddUserForm ? "Zrušiť" : "Pridať používateľa"}
                    </button>
                </div>

                {/* AddUserForm shown/hidden based on state */}
                {showAddUserForm && <AddUserForm onSave={handleAddUser}/>}

                {/* List users */}
                {users.map((user) => (
                    <UserDetails
                        key={user.userId}
                        user={user}
                        onEdit={() => startEditing(user.userId)}
                        onSave={(updatedUser) => handleUserEdit(user.userId, updatedUser)}
                        onCancel={cancelEditing}
                        // onDelete={() => handleUserDelete(user.userId)}
                        onDelete={() => requestDeleteUser(user.userId)}
                        isEditing={editUserId === user.userId}
                    />
                ))}
                <ConfirmationDialog
                    isOpen={showConfirmationDialog}
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                    message="Ste si istí, že chcete odstrániť tohto používateľa?"
                />
            </main>
        </div>
    );
};

export default UserManagement;

