/**
 * Locations.js
 *
 * This component is responsible for rendering and managing a list of locations
 * for work shifts. It allows for the addition, editing, and deletion of location
 * details such as name, address, contact information, and associated images.
 *
 * Author: Oleksandr Molodchyk
 * Years: 2022-2024
 *
 */

import React, { useState, useEffect } from 'react';
import axios from "axios";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faTrash, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';

import ConfirmationDialog from '../common/ConfirmationDialog';

import Header from '../common/Header';
import NavigationBar from '../common/NavigationBar';
import AddLocation from './AddLocation';

import dashStyles from "../dashboard/Dashboard.module.css";
import styles from './Locations.module.css';

// LocationItem within Locations.js
const LocationItem = ({ location, onEdit, onDelete }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editableLocation, setEditableLocation] = useState({});

    // Function to initialize editableLocation with current location data
    const initEdit = () => {
        setEditableLocation({
            ...location,
            coordinates: `${location.latitude}, ${location.longitude}`,
            latitude: location.latitude,
            longitude: location.longitude
        });
        setIsEditing(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'coordinates') {
            const parts = value.split(',').map(v => parseFloat(v.trim()));
            if (parts.length === 2) {
                const [latitude, longitude] = parts;
                setEditableLocation(prev => ({
                    ...prev,
                    latitude,
                    longitude,
                    coordinates: value
                }));
            }
        } else {
            setEditableLocation(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleSave = async () => {
        const { coordinates, ...locationData } = editableLocation;
        // Convert latitude and longitude to string
        locationData.latitude = String(locationData.latitude);
        locationData.longitude = String(locationData.longitude);
        setIsEditing(false);
        await onEdit(locationData);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleDelete = () => {
        onDelete(location.storeId);
    };

    return (
        <div className={styles.locationItem}>
            {isEditing ? (
                // Edit mode UI
                <>
                    <div className={styles.editFields}>
                        <label htmlFor="storeName" className="font-roboto text-gray-400">
                            Názov obchodu
                        </label>
                        <input
                            type="text"
                            id="storeName"
                            name="storeName"
                            placeholder="Zadajte názov obchodu"
                            value={editableLocation.storeName}
                            onChange={handleChange}
                            className={styles.locationInput}
                        />
                        <label htmlFor="location" className="font-roboto text-gray-400">
                            Adresa
                        </label>
                        <input
                            type="text"
                            id="location"
                            name="location"
                            placeholder="Zadajte adresu"
                            value={editableLocation.location}
                            onChange={handleChange}
                            className={styles.locationInput}
                        />
                        <label htmlFor="location" className="font-roboto text-gray-400">
                            Súradnice
                        </label>
                        <input
                            type="text"
                            id="coordinates"
                            name="coordinates"
                            placeholder="Zadajte súradnice, napr. 48.6913, 10.9333"
                            value={editableLocation.coordinates}
                            onChange={handleChange}
                            className={styles.locationInput}
                        />
                        <label htmlFor="contactInformation" className="font-roboto text-gray-400">
                            Kontaktné informácie
                        </label>
                        <input
                            type="text"
                            id="contactInformation"
                            name="contactInformation"
                            placeholder="Zadajte kontaktné informácie"
                            value={editableLocation.contactInformation}
                            onChange={handleChange}
                            className={styles.locationInput}
                        />
                        <label htmlFor="imageUrl" className="font-roboto text-gray-400">
                            URL obrázka
                        </label>
                        <input
                            type="text"
                            id="imageUrl"
                            name="imageUrl"
                            placeholder="Zadajte URL obrázka"
                            value={editableLocation.imageUrl}
                            onChange={handleChange}
                            className={styles.locationInput}
                        />
                    </div>
                    {/* Buttons */}
                    <div className={styles.actionButtons}>
                        <button onClick={handleCancel} className={styles.cancelButton}>
                            <FontAwesomeIcon icon={faTimes}/> Zrušiť
                        </button>
                        <button onClick={handleSave} className={styles.saveButton}>
                            <FontAwesomeIcon icon={faSave}/> Uložiť
                        </button>
                    </div>
                </>
            ) : (
                // View mode UI
                <>
                    <img
                        src={location.imageUrl}
                        alt="Obrázok obchodu"
                        className={styles.locationImage}
                    />
                    <div>
                        <h3 className={styles.storeName}>{location.storeName}</h3>
                        <p className={styles.storeDetails}>{location.location}</p>
                        <p className={styles.storeDetails}>{location.contactInformation}</p>
                    </div>
                    <div className={styles.actionButtons}>
                        <button onClick={initEdit} className={styles.editButton}>
                            <FontAwesomeIcon icon={faEdit}/> Upraviť
                        </button>
                        <button onClick={handleDelete} className={styles.deleteButton}>
                            <FontAwesomeIcon icon={faTrash}/> Odstrániť
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};


const Locations = () => {
    const [locations, setLocations] = useState([]);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [locationIdToDelete, setLocationIdToDelete] = useState(null);

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(`https://rezervacny-system-a1350e3ff1d6.herokuapp.com/stores`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setLocations(response.data);
            } catch (error) {
                console.error("Error fetching locations:", error);
            }
        };

        fetchLocations();
    }, []);

    const handleAddLocationSuccess = (newLocation) => {
        setLocations([...locations, newLocation]);
    };

    const handleEditLocation = async (updatedLocation) => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token not found");
            return;
        }

        console.log("updatedLocation: ", updatedLocation)
        try {
            await axios.put(`https://rezervacny-system-a1350e3ff1d6.herokuapp.com/stores/${updatedLocation.storeId}`, updatedLocation, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setLocations(locations.map(location => location.storeId === updatedLocation.storeId ? updatedLocation : location));
        } catch (error) {
            console.error("Error updating location:", error);
        }
    };

    const handleDeleteLocation = async () => {
        if (!locationIdToDelete) return;

        try {
            const token = localStorage.getItem("token");
            await axios.delete(`https://rezervacny-system-a1350e3ff1d6.herokuapp.com/stores/${locationIdToDelete}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            // Remove the deleted location from the state
            setLocations(locations.filter(location => location.storeId !== locationIdToDelete));
            // Close the dialog
            setIsConfirmationDialogOpen(false);
            setLocationIdToDelete(null);
        } catch (error) {
            console.error("Error deleting the location:", error);
        }
    };

    const requestDeleteLocation = (locationId) => {
        setLocationIdToDelete(locationId);
        setIsConfirmationDialogOpen(true);
    };

    return (
        <div className={dashStyles.dashboardContainer}>
            <Header />
            <NavigationBar />
            <main className={dashStyles.dashboardMain}>
                <AddLocation onAddLocationSuccess={handleAddLocationSuccess} />
                {locations.map((location) => (
                    <LocationItem
                        key={location.storeId}
                        location={location}
                        onEdit={handleEditLocation}
                        onDelete={() => requestDeleteLocation(location.storeId)}
                    />
                ))}
                <ConfirmationDialog
                    isOpen={isConfirmationDialogOpen}
                    onClose={() => setIsConfirmationDialogOpen(false)}
                    onConfirm={handleDeleteLocation}
                    message="Ste si istí, že chcete odstrániť túto lokalitu?"
                />
            </main>
        </div>
    );
};

export default Locations;

