// src/components/common/NavigationBar.js

import React from 'react';
import { Link } from 'react-router-dom';
import navStyles from './NavigationBar.module.css';

const NavigationBar = () => (
    <aside className={navStyles.dashboardNav}>
        <ul className={navStyles.navList}>
            <li className={navStyles.navItem}><Link className={navStyles.navLink} to="/events">Udalosti</Link></li>
            <li className={navStyles.navItem}><Link className={navStyles.navLink} to="/shifts">Zmeny</Link></li>
            <li className={navStyles.navItem}><Link className={navStyles.navLink} to="/chat">Chat</Link></li>
            <li className={navStyles.navItem}><Link className={navStyles.navLink} to="/user-management">Používatelia</Link>
            </li>
            <li className={navStyles.navItem}><Link className={navStyles.navLink} to="/attendance">Dochádzky</Link></li>
            {/* Add any other navigation links here */}
        </ul>
        <ul className={navStyles.navList}>
            <li className={navStyles.navItem}><Link className={navStyles.navLink} to="/locations">Lokácie</Link></li>
        </ul>
    </aside>
);

export default NavigationBar;

