// src/components/Login.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import logoImg from '../../assets/img.png'; // Adjust the path as needed to match your project structure
import styles from './Login.module.css';

const maxLoginAttempts = 5;

const Login = ({ onLoginSuccess }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loginAttempts, setLoginAttempts] = useState(0);
    const [isBlocked, setIsBlocked] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const unblockTime = localStorage.getItem('unblockTime');
        if (unblockTime && new Date().getTime() < unblockTime) {
            setIsBlocked(true);
            setTimeout(() => {
                setIsBlocked(false);
                localStorage.removeItem('unblockTime');
            }, unblockTime - new Date().getTime());
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isBlocked) {
            setError('Príliš veľa pokusov o prihlásenie. Prosím, počkajte pred ďalším pokusom.');
            return;
        }

        setError(''); // Reset the error message on a new submission
        try {
            const response = await axios.post('https://rezervacny-system-a1350e3ff1d6.herokuapp.com/login', { email, password });
            const { token } = response.data;
            const userFromToken = JSON.parse(atob(token.split('.')[1]));
            onLoginSuccess({ firstName: userFromToken.email }, token);
            navigate('/dashboard');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                const attemptsLeft = maxLoginAttempts - (loginAttempts + 1);
                setError(`Prihlásenie zlyhalo. Skontrolujte svoje prihlasovacie údaje. Zostáva ${attemptsLeft} pokusov.`);
                setLoginAttempts(prevAttempts => {
                    const newAttempts = prevAttempts + 1;
                    if (newAttempts >= maxLoginAttempts) {
                        setError('Príliš veľa pokusov o prihlásenie. Prosím, počkajte pred ďalším pokusom.');
                        const unblockTime = new Date().getTime() + 30 * 1000; // 30 seconds from now
                        localStorage.setItem('unblockTime', unblockTime.toString());
                        setIsBlocked(true);
                        setTimeout(() => {
                            setIsBlocked(false);
                            localStorage.removeItem('unblockTime');
                        }, 30000); // Unblock after 30 seconds
                        return 0;
                    }
                    return newAttempts;
                });
            } else {
                setError('Došlo k chybe. Skúste to prosím neskôr.');
            }
        }
    };

    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginBox}>
                <div className={styles.logoContainer}>
                    <img
                        src={logoImg}
                        alt="Logo"
                        className={styles.logo}
                    />
                </div>
                <form onSubmit={handleSubmit} className={styles.loginForm}>
                    <div className={styles.inputFieldContainer}>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                            autoComplete="username"
                            className={styles.inputField}
                        />
                    </div>
                    <div className={styles.inputFieldContainer}>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Heslo"
                            required
                            autoComplete="current-password"
                            className={styles.inputField}
                        />
                    </div>
                    <div>
                        <button
                            type="submit"
                            disabled={isBlocked}
                            className={styles.submitButton}
                        >
                            Prihlásiť sa
                        </button>
                    </div>
                    {error && <div className={styles.errorMessage}>{error}</div>}
                </form>
            </div>
        </div>
    );
};

export default Login;

