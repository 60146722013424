// AddLocation.js
// This component renders a form allowing users to input and submit new location details for work shifts, such as name, address, contact information, and an image URL.

import React, { useState } from 'react';
import axios from 'axios';

import '../../index.css';
import styles from './AddLocation.module.css';

const AddLocation = ({ onAddLocationSuccess }) => {
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        storeName: '',
        location: '',
        coordinates: '',  // New field for combined coordinates
        contactInformation: '',
        imageUrl: ''
    });

    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSaveStore = async () => {
        const { coordinates, ...otherData } = formData;
        const [latitude, longitude] = coordinates.split(',').map(coord =>
            parseFloat(coord).toFixed(6)  // Round to 6 decimal places
        );

        const dataToSend = {
            ...otherData,
            latitude,
            longitude
        };
        console.log("dataToSend: ", dataToSend)

        try {
            const response = await axios.post(
                'https://rezervacny-system-a1350e3ff1d6.herokuapp.com/stores',
                dataToSend,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );
            onAddLocationSuccess(response.data);
            console.log("Store added:", response.data);
            setFormData({
                storeName: '',
                location: '',
                coordinates: '',
                contactInformation: '',
                imageUrl: ''
            });
            setShowForm(false);
        } catch (error) {
            console.error("Error saving store:", error);
        }
    };

    return (
        <div className={styles.container}>
            <button
                className="toggleButton"
                onClick={toggleForm}
            >
                {showForm ? "Zrušiť" :  "Pridať obchod"}
            </button>

            {showForm && (
                <div className={styles.formContainer}>
                    <div className={styles.form}>
                        <div className={styles.formGroup}>
                            <label htmlFor="storeName" className={styles.formLabel}>
                                Názov obchodu
                            </label>
                            <input
                                type="text"
                                name="storeName"
                                value={formData.storeName}
                                onChange={handleChange}
                                className={styles.formInput}
                                placeholder="Zadajte názov obchodu"
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="location" className={styles.formLabel}>
                                Adresa
                            </label>
                            <input
                                type="text"
                                name="location"
                                value={formData.location}
                                onChange={handleChange}
                                className={styles.formInput}
                                placeholder="Zadajte adresu"
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="coordinates" className={styles.formLabel}>
                                Súradnice
                            </label>
                            <input
                                type="text"
                                name="coordinates"
                                value={formData.coordinates}
                                onChange={handleChange}
                                className={styles.formInput}
                                placeholder="Zadajte súradnice, napr. 48.6913, 10.9333"
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="contactInformation" className={styles.formLabel}>
                                Kontaktné informácie
                            </label>
                            <input
                                type="text"
                                name="contactInformation"
                                value={formData.contactInformation}
                                onChange={handleChange}
                                className={styles.formInput}
                                placeholder="Zadajte kontaktné informácie"
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="imageUrl" className={styles.formLabel}>
                                URL obrázka
                            </label>
                            <input
                                type="text"
                                name="imageUrl"
                                value={formData.imageUrl}
                                onChange={handleChange}
                                className={styles.formInput}
                                placeholder="Zadajte URL obrázka"
                            />
                        </div>
                        <button
                            type="button"
                            onClick={handleSaveStore}
                            className={styles.formButton}
                        >
                            Uložiť
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddLocation;

