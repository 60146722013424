// src/components/shiftManagement/ShiftManagement.js

import React, {useCallback, useState} from 'react';
import axios from 'axios';

import animationData from '../../assets/Animation.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus, faChevronUp, faChevronDown, faTimes, faCheck, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';

import AddShiftModal from './AddShiftModal';
import dashStyles from "../dashboard/Dashboard.module.css";
import Header from "../common/Header";
import NavigationBar from "../common/NavigationBar";
import styles from "./ShiftManagement.module.css";
import ConfirmationDialog from "../common/ConfirmationDialog";
import ShiftFilters from "./filters/ShiftFilters";
import Lottie from "react-lottie-player";

const ShiftManagement = () => {
    const [shifts, setShifts] = useState([]);
    const [showAddShiftModal, setShowAddShiftModal] = useState(false);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteShiftId, setDeleteShiftId] = useState(null);
    const [lottieVisible, setLottieVisible] = useState(false);
    const [renderLottie, setRenderLottie] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const [filter, setFilter] = useState({
        dateRange: 'tyzden', // 'den', 'tyzden', 'mesiac', 'rok'
        date: new Date(), // This will be the starting date for filtering
        store: 'all', // Store ID or 'all'
        timeOfDay: 'all', // 'morning', 'afternoon', 'other'
        availability: 'all', // 'free', 'booked'
        worker: 'all', // Worker ID or 'all'
    });


    const saveShift = async (id, updatedShift) => {
        const token = localStorage.getItem('token'); // Assuming you're using token-based authentication
        try {
            const response = await axios.put(`https://rezervacny-system-a1350e3ff1d6.herokuapp.com/shifts/${id}`, updatedShift, {
                headers: { Authorization: `Bearer ${token}` },
            });
            // Handle response
            console.log("Shift updated successfully", response.data);
            return response.data; // Return the updated shift or other relevant data
        } catch (error) {
            console.error("Error updating shift:", error);
            throw error; // Re-throw or handle error as needed
        }
    };

    // Handlers for UI actions
    const handleToggleExpand = id => {
        setShifts(shifts.map(shift => shift.id === id ? { ...shift, isExpanded: !shift.isExpanded } : shift));
    };

    const handleStartEdit = (id) => {
        setShifts(shifts.map(shift => {
            if(shift.id === id) {
                return { ...shift, isEditing: true };
            }
            return shift;
        }));
    };

    const handleSaveEdit = (id, updatedShift) => {
        // Assume a function saveShift is available to save the edited shift
        saveShift(id, updatedShift).then(() => {
            setShifts(shifts.map(shift => {
                if(shift.id === id) {
                    return { ...updatedShift, isEditing: false, isExpanded: false };
                }
                return shift;
            }));
        });
    };

    const handleCancelEdit = (id) => {
        setShifts(shifts.map(shift => {
            if(shift.id === id) {
                return { ...shift, isEditing: false };
            }
            return shift;
        }));
    };

    const handleAddShift = () => {
    };

    const handleDeleteShift = async (shiftId) => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error("Token not found");
            return;
        }
        try {
            await axios.delete(`https://rezervacny-system-a1350e3ff1d6.herokuapp.com/shifts/${shiftId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            // Filter out the deleted shift from state
            setShifts(currentShifts => currentShifts.filter(shift => shift.id !== shiftId));
        } catch (error) {
            console.error("Error deleting shift:", error);
        }
    };

    const handleShowLottie = useCallback(() => {
        setRenderLottie(true);
        // Wait a tick to ensure the component is in the DOM before starting the transition
        requestAnimationFrame(() => setLottieVisible(true));
    }, []);


    const handleHideLottie = useCallback(() => {
        setLottieVisible(false);
        // Wait for the fade-out transition to complete before removing the component from the DOM
        setTimeout(() => setRenderLottie(false), 500); // Match this duration to your CSS transition
    }, []);

    return (
        <div className={dashStyles.dashboardContainer}>
            <Header />
            <NavigationBar />
            <div className={dashStyles.dashboardMain}>
                <div className={styles.addUserButtonContainer}>
                    <button className={styles.button} onClick={() => setShowAddShiftModal(true)}>
                        <FontAwesomeIcon icon={faPlus}/> Pridať zmenu
                    </button>
                </div>
                {showAddShiftModal && (
                    <AddShiftModal
                        onClose={() => setShowAddShiftModal(false)}
                        onAddShift={handleAddShift}
                    />
                )}

                <ShiftFilters filter={filter}
                              setFilter={setFilter}
                              setShifts={setShifts}
                              isFetching={isFetching}
                              setIsFetching={setIsFetching}
                              setLottieVisible={setLottieVisible}
                              handleShowLottie={handleShowLottie}
                              handleHideLottie={handleHideLottie} />

                {
                    renderLottie && (
                        <div className={styles.lottieFade} style={{ opacity: lottieVisible ? 1 : 0 }}>
                            <Lottie
                                loop
                                animationData={animationData}
                                play
                                style={{ width: 150, height: 150, margin: '0 auto' }} // Adjust styling as needed
                            />
                        </div>
                    )
                }

                <div className={styles.shiftsContainer}>
                    {shifts.map((shift) => (
                        <div key={shift.id} className={styles.shiftContainer}>
                            <div className={styles.shiftHeader}>
                                <img src={shift.imageUrl} alt="Store" className={styles.storeImage}/>
                                <div className={styles.storeDetails}>
                                    <div className={styles.storeName}>{shift.storeName}</div>
                                    <div className={styles.bookingStatus}>
                                        {shift.isBooked ? `Booked by ${shift.bookedBy}` : "Voľná"}
                                    </div>
                                </div>
                                <div className={styles.dateTimeDetails}>
                                    <p>{shift.date}</p>
                                    <p>{shift.startTime} - {shift.endTime}</p>
                                </div>
                                <div className={styles.editButtons}>
                                    {
                                        shift.isEditing ? (
                                            <>
                                                <button className={`${styles.button} ${styles.buttonCancel}`}
                                                        onClick={() => handleCancelEdit(shift.id)}>
                                                    <FontAwesomeIcon icon={faTimes}/> Zrušiť
                                                </button>
                                                <button className={`${styles.button} ${styles.buttonSave}`}
                                                        onClick={() => handleSaveEdit(shift.id)}>
                                                    <FontAwesomeIcon icon={faCheck}/> Uložiť
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button className={`${styles.button} ${styles.buttonEdit}`}
                                                        onClick={() => handleStartEdit(shift.id)}>
                                                    <FontAwesomeIcon icon={faEdit}/> Upraviť
                                                </button>
                                                <button
                                                    className={`${styles.button} ${styles.buttonDelete}`}
                                                    onClick={() => {
                                                        setDeleteShiftId(shift.id);
                                                        setShowDeleteConfirmation(true);
                                                    }}>
                                                    <FontAwesomeIcon icon={faTrash}/> Odstrániť
                                                </button>
                                            </>
                                        )
                                    }
                                    <button className={styles.expandIcon} onClick={() => handleToggleExpand(shift.id)}>
                                        <FontAwesomeIcon
                                            icon={shift.isExpanded ? faChevronUp : faChevronDown}
                                            style={{color: 'white'}} // Inline style for white color
                                        />

                                    </button>
                                </div>
                            </div>
                            {shift.isExpanded && (
                                <div className="mt-4">
                                    {/* Additional shift details */}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {showDeleteConfirmation && (
                    <ConfirmationDialog
                        isOpen={showDeleteConfirmation}
                        onClose={() => setShowDeleteConfirmation(false)}
                        onConfirm={() => {
                            handleDeleteShift(deleteShiftId);
                            setShowDeleteConfirmation(false);
                        }}
                        message="Ste si istí, že chcete odstrániť túto zmenu?"
                    />
                )}
            </div>
        </div>
    );
};

export default ShiftManagement;

