// src/components/shiftManagement/AddShiftModal.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import '../../index.css';
import styles from './AddShiftModal.module.css';

const AddShiftModal = ({ onClose, onAddShift }) => {
    const [shiftDetails, setShiftDetails] = useState({
        selectedStoreId: '',
        shiftDate: '',
        startTime: '',
        endTime: '',
        breakDuration: 30
    });
    const [stores, setStores] = useState([]);

    useEffect(() => {
        const fetchStores = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get('https://rezervacny-system-a1350e3ff1d6.herokuapp.com/stores', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setStores(response.data);
            } catch (error) {
                console.error('Error fetching stores:', error);
            }
        };

        fetchStores().catch(console.error);
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setShiftDetails(prevDetails => ({ ...prevDetails, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = localStorage.getItem('token');

        const submissionData = {
            storeId: shiftDetails.selectedStoreId,
            shiftDate: shiftDetails.shiftDate, // Assuming this contains only the date
            startTime: shiftDetails.startTime, // Assuming this contains only the time
            endTime: shiftDetails.endTime, // Assuming this contains only the time
            breakDuration: parseInt(shiftDetails.breakDuration, 10),
            isBooked: false,
            bookedBy: null
        };

        console.log("submissionData: ", submissionData);

        await axios.post('https://rezervacny-system-a1350e3ff1d6.herokuapp.com/shifts', submissionData, {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => {
            onAddShift(response.data);
            onClose();
        }).catch(error => {
            console.error('Error saving shift:', error);
        });
    };

    return (
        <div className={styles['add-shift-modal']}>
            <div className={styles['modal-content']}>
                <span className={styles['close-button']} onClick={onClose}>&times;</span>
                <h2>Pridať Novú Zmenu</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="store">Obchod</label>
                    <select
                        id="store"
                        name="selectedStoreId"
                        className={styles.input}
                        value={shiftDetails.selectedStoreId}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Vyberte obchod</option>
                        {stores.map(store => (
                            <option key={store.storeId} value={store.storeId}>
                                {store.storeName}
                            </option>
                        ))}
                    </select>

                    <label htmlFor="shiftDate">Dátum zmeny</label>
                    <input
                        type="date"
                        id="shiftDate"
                        name="shiftDate"
                        className={styles.input}
                        value={shiftDetails.shiftDate}
                        onChange={handleInputChange}
                        required
                    />

                    <label htmlFor="startTime">Začiatok zmeny</label>
                    <input
                        type="time"
                        id="startTime"
                        name="startTime"
                        className={styles.input}
                        value={shiftDetails.startTime}
                        onChange={handleInputChange}
                        required
                    />

                    <label htmlFor="endTime">Koniec zmeny</label>
                    <input
                        type="time"
                        id="endTime"
                        name="endTime"
                        className={styles.input}
                        value={shiftDetails.endTime}
                        onChange={handleInputChange}
                        required
                    />

                    <label htmlFor="breakDuration">Dĺžka prestávky (min)</label>
                    <input
                        type="number"
                        id="breakDuration"
                        name="breakDuration"
                        className={styles.input}
                        value={shiftDetails.breakDuration}
                        onChange={handleInputChange}
                        min="0"
                        required
                    />

                    <button className="toggleButton" type="submit">
                        <FontAwesomeIcon icon={faSave} className={styles.saveIcon}/> Uložiť
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddShiftModal;

