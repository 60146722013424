// src/components/common/Header.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logoImg from '../../assets/img.png'; // Adjust the path as needed
import accountIcon from '../../assets/account-80.png'; // Adjust the path as needed
import notificationIcon from '../../assets/notification-80.png'; // Adjust the path as needed
import settingsIcon from '../../assets/settings-80.png'; // Adjust the path as needed
import headerStyles from './Header.module.css'; // Import styles

const Header = () => {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timerId = setInterval(() => setCurrentTime(new Date()), 1000);
        return () => clearInterval(timerId);
    }, []);

    const getCurrentDateTime = () => {
        return currentTime.toLocaleString('sk-SK', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const handleIconClick = (path) => navigate(path);

    return (
        <header className={headerStyles.dashboardHeader}>
            <img src={logoImg} alt="Logo" className={headerStyles.logo} onClick={() => handleIconClick('/dashboard')} />
            <div className={headerStyles.dateTime}>{getCurrentDateTime()}</div>
            <div className={headerStyles.icons}>
                <img src={notificationIcon} alt="Notifications" className={headerStyles.icon} onClick={() => handleIconClick('/notifications')} />
                <img src={settingsIcon} alt="Settings" className={headerStyles.icon} onClick={() => handleIconClick('/settings')} />
                <img src={accountIcon} alt="Account" className={headerStyles.icon} onClick={() => handleIconClick('/account')} />
            </div>
        </header>
    );
};

export default Header;

