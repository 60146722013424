/* StoreFilter.js */

import React, { useState, useRef } from 'react';
import styles from './StoreFilter.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';

const StoreFilter = ({ stores, filter, setFilter }) => {
    const [storeDropdownOpen, setStoreDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleStoreChange = (storeId) => {
        console.log("here");
        if (storeId === 'all') {
            if (filter.store === 'all' || filter.store.length === stores.length) {
                setFilter(prev => ({ ...prev, store: [] }));  // Deselect all
            } else {
                setFilter(prev => ({ ...prev, store: 'all' }));  // Select all
            }
        } else {
            if (filter.store === 'all') {
                // If 'all' was previously selected, start new array from other stores
                const changedStores = stores.filter(s => s.storeId !== storeId).map(s => s.storeId);
                setFilter(prev => ({ ...prev, store: changedStores }));
            } else {
                setFilter(prev => ({
                    ...prev,
                    store: prev.store.includes(storeId)
                        ? prev.store.filter(id => id !== storeId)
                        : [...prev.store, storeId]
                }));
            }
        }
    };

    const toggleDropdown = () => {
        console.log("here2");
        setStoreDropdownOpen(prev => !prev);
    };

    React.useEffect(() => {
        console.log("here3");
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setStoreDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className={styles.container} ref={dropdownRef}>
            <label htmlFor="store" className={styles.label}>Lokácie</label>
            <button
                id="store"
                name="store"
                className={styles.storeDropdownButton}
                onClick={toggleDropdown}
                aria-expanded={storeDropdownOpen}
                aria-haspopup="true"
            >
                {filter.store === 'all' || filter.store.length === 0
                    ? 'Všetky'
                    : `${filter.store.length} vybrané`}
                <FontAwesomeIcon icon={faChevronDown} className={styles.icon}/>
            </button>
            {storeDropdownOpen && (
                <div className={styles.storeDropdownMenu}>
                    <button
                        className={`${styles.storeDropdownItem} ${filter.store === 'all' ? styles.selected : ''}`}
                        onClick={() => handleStoreChange('all')}
                    >
                        Všetky
                        {filter.store === 'all' && <FontAwesomeIcon icon={faCheck} className={styles.checkIcon}/>}
                    </button>
                    {stores.map(store => (
                        <button
                            key={store.storeId}
                            className={`${styles.storeDropdownItem} ${filter.store === 'all' || filter.store.includes(store.storeId) ? styles.selected : ''}`}
                            onClick={() => handleStoreChange(store.storeId)}
                        >
                            {store.storeName}
                            {(filter.store === 'all' || filter.store.includes(store.storeId)) &&
                                <FontAwesomeIcon icon={faCheck} className={styles.checkIcon}/>}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default StoreFilter;

