// src/components/events/Events.js

import React from 'react';
import NavigationBar from '../common/NavigationBar';
import Header from '../common/Header';
import styles from './Events.module.css'; // Ensure you have created this CSS module
import dashStyles from "../dashboard/Dashboard.module.css"; // Reuse dashboard container styles

const Events = () => {
    return (
        <div className={dashStyles.dashboardContainer}>
            <Header />
            <NavigationBar />
            <main className={styles.eventsMain}>
                <h1>Udalosti</h1>
                <p>Tu môžete spravovať a sledovať nadchádzajúce udalosti.</p>
                {/* Add events management functionalities here */}
            </main>
        </div>
    );
}

export default Events;
