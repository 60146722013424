/**
 * CurrentUserDetails.js
 * This component is used to display the details of the current user.
 * It fetches the user's information based on the provided userId prop.
 * While the data is loading, it shows a loading message.
 * If no user is selected, it displays a relevant message.
 *
 * The component also provides an "Edit" button that, when clicked, will call the onEditToggle
 * prop function to enable editing mode for the user's details.
 *
 * Props:
 *  - userId: the ID of the user to display.
 *  - onEditToggle: function to be called when toggling edit mode.
 *
 * Author: Oleksandr Molodchyk
 * Years: 2021-2024
 */

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from './CurrentUserDetails.module.css';

const CurrentUserDetails = ({ userId, onEditToggle }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [edit, setEdit] = useState(false);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        role: "",
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchCurrentUser = async () => {
            setLoading(true);
            const token = localStorage.getItem("token");
            if (token) {
                try {
                    const response = await axios.get(
                        `https://rezervacny-system-a1350e3ff1d6.herokuapp.com/users/${userId}`,
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    );
                    setCurrentUser(response.data);
                    setFormData({
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        email: response.data.email,
                        phoneNumber: response.data.phoneNumber,
                        role: response.data.role,
                    });
                } catch (error) {
                    setError(error);
                } finally {
                    setLoading(false);
                }
            } else {
                setError(new Error("Token not found or userId is undefined."));
                setLoading(false);
            }
        };
        fetchCurrentUser();
    }, [userId]);
    if (loading) return <p>Loading user details...</p>;
    if (error) return <p>Error: {error.message || error}</p>;
    if (!currentUser) return <p>No user selected.</p>;
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token");
        if (token && edit) {
            try {
                await axios.put(
                    `https://rezervacny-system-a1350e3ff1d6.herokuapp.com/users/${currentUser.userId}`,
                    formData,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                setEdit(false);
                setCurrentUser({ ...currentUser, ...formData });
                if (onEditToggle) onEditToggle(false);
            } catch (error) {
                setError(error);
            }
        }
    };

    const translateRole = (role) => {
        switch(role) {
            case 'owner':
                return 'Majiteľ';
            case 'store':
                return 'Obchod';
            case 'user':
                return 'Brigádnik';
            case 'admin':
                return 'Manažér';
            default:
                return role; // Or any default you see fit
        }
    };

    return (
        <div className={styles.container}>
            {edit ? (
                <form onSubmit={handleSubmit} className={styles.form}>
                    <label className={styles.label} htmlFor="firstName">Meno</label>
                    <input
                        className={styles.input}
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="Meno"
                    />
                    <label className={styles.label} htmlFor="lastName">Priezvisko</label>
                    <input
                        className={styles.input}
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Priezvisko"
                    />
                    <label className={styles.label} htmlFor="email">E-mail</label>
                    <input
                        className={styles.input}
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="E-mail"
                    />
                    <label className={styles.label} htmlFor="phoneNumber">Telefónne číslo</label>
                    <input
                        className={styles.input}
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        placeholder="Telefónne číslo"
                    />
                    <div className={styles.flexSpaceX3}>
                        <button
                            type="button"
                            onClick={() => {
                                setEdit(false);
                                if (onEditToggle) onEditToggle(false);
                            }}
                            className={styles.cancelButton}
                        >
                            <FontAwesomeIcon icon={faTimes} /> Zrušiť
                        </button>
                        <button
                            type="submit"
                            className={styles.saveButton}
                        >
                            <FontAwesomeIcon icon={faSave} /> Uložiť
                        </button>
                    </div>
                </form>
            ) : (
                <div className={styles.userDetails}>
                    <div className={styles.userDetailBlock}>
                        <span className={styles.userDetail}>{currentUser.firstName} {currentUser.lastName}</span>
                    </div>
                    <div className={styles.userDetailBlock}>
                        <span className={styles.userDetail}>{currentUser.email}</span>
                    </div>
                    <div className={styles.userDetailBlock}>
                        <span className={styles.userDetail}>{currentUser.phoneNumber}</span>
                    </div>
                    <div className={styles.userDetailBlock}>
                        <span className={styles.userDetail}>{translateRole(currentUser.role)}</span>
                    </div>
                    <button
                        onClick={() => {
                            setEdit(true);
                            if (onEditToggle) onEditToggle(true);
                        }}
                        className={styles.editButton}
                    >
                        <FontAwesomeIcon icon={faEdit} /> Upraviť
                    </button>
                </div>
            )}
        </div>
    );

};

export default CurrentUserDetails;

