/**
 * AddUserForm.js
 *
 * This component provides a form for adding a new user to the system. It is meant to be used
 * within the user management module of an administrative dashboard. The form includes fields for
 * first name, last name, email, phone number, password and role. On submission,
 * it makes an API call to create a new user with the provided details.
 *
 * Upon successful creation, the new user's data is lifted up to the parent component through the
 * onSave prop, allowing the parent to update the overall state of the user list without needing to
 * refetch the entire list. The form also resets its fields to their initial state after submission
 * to allow for another user to be added without manual field clearing.
 *
 * Props:
 *  onSave: Function to be called with the new user's data after a successful creation.
 *
 * State:
 *  newUserData: An object that holds the form's current values for each field, with keys
 *               corresponding to the user attributes.
 *
 * Usage:
 * <AddUserForm onSave={handleNewUserData} />
 *
 * Author: Oleksandr Molodchyk
 * Years: 2021-2024
 */

import React, { useState } from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import styles from './AddUserForm.module.css';

const AddUserForm = ({ onSave }) => {
    const [newUserData, setNewUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        role: 'Brigádnik', // Default to 'Brigádnik' which corresponds to 'user'
        iban: ''
    });

    // Additional state to manage the dropdown
    const [selectedRole, setSelectedRole] = useState('Brigádnik'); // The default role

    // Function to handle selecting a role from the dropdown
    const selectRole = (role) => {
        setSelectedRole(role);
        setNewUserData({ ...newUserData, role: role });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Map the form roles to the database roles
        const roleMappings = {
            'Brigadnik': 'user', // Changed from 'Brigádnik'
            'Manazer': 'admin',  // Changed from 'Manažer'
            'Obchod': 'store',
            'Majitel': 'owner',  // Changed from 'Majiteľ'
        };
        // Use a function to map the selected role to an ASCII key
        const asciiRole = mapRoleToAscii(newUserData.role);
        // Prepare data for submission based on role
        let submitData = {
            firstName: newUserData.firstName,
            lastName: newUserData.lastName,
            email: newUserData.email,
            phoneNumber: newUserData.phoneNumber,
            password: newUserData.password,
            role: roleMappings[asciiRole], // Use the ASCII key for lookup
            iban: newUserData.iban
        };
        const token = localStorage.getItem('token');

        if (token) {
            try {
                const response = await axios.post('https://rezervacny-system-a1350e3ff1d6.herokuapp.com/users', submitData, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                onSave(response.data); // Now, response.data is the full user object
                // Reset the form fields and UI states as necessary
                setNewUserData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    password: '',
                    role: 'Brigádnik',
                    iban: ''
                });
                setSelectedRole('Brigádnik');
            } catch (error) {
                console.error("Failed to create user:", error);
            }
        } else {
            console.error("No token found. Please login.");
        }
    };

    const mapRoleToAscii = (role) => {
        switch (role) {
            case 'Brigádnik': return 'Brigadnik';
            case 'Manažer': return 'Manazer';
            case 'Majiteľ': return 'Majitel';
            default: return role; // Return the original role if it doesn't need mapping
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles['add-user-form']}>
            <label className={styles['form-label']} htmlFor="firstName">Meno</label>
            <input
                id="firstName"
                type="text"
                name="firstName"
                value={newUserData.firstName}
                onChange={handleChange}
                placeholder="Zadajte meno"
                className={styles['form-input']}
            />

            <label className={styles['form-label']} htmlFor="lastName">Priezvisko</label>
            <input
                id="lastName"
                type="text"
                name="lastName"
                value={newUserData.lastName}
                onChange={handleChange}
                placeholder="Zadajte priezvisko"
                className={styles['form-input']}
            />

            <label className={styles['form-label']} htmlFor="email">Email</label>
            <input
                id="email"
                type="email"
                name="email"
                value={newUserData.email}
                onChange={handleChange}
                placeholder="Zadajte email"
                autoComplete="off"
                className={styles['form-input']}
            />

            <label className={styles['form-label']} htmlFor="phoneNumber">Telefónne číslo</label>
            <input
                id="phoneNumber"
                type="text"
                name="phoneNumber"
                value={newUserData.phoneNumber}
                onChange={handleChange}
                placeholder="Zadajte telefónne číslo"
                className={styles['form-input']}
            />

            <label className={styles['form-label']} htmlFor="password">Heslo</label>
            <input
                id="password"
                type="password"
                name="password"
                value={newUserData.password}
                onChange={handleChange}
                placeholder="Zadajte heslo"
                autoComplete="new-password"
                className={styles['form-input']}
            />

            <label className={styles['form-label']} htmlFor="role">Rola</label>
            <div className={styles['dropdown']}>
                <button
                    type="button"
                    id="role"
                    className={styles.dropbtn}
                >
                    {selectedRole}
                </button>
                <div className={styles.dropdownContent}>
                    <button type="button" onClick={() => selectRole('Brigádnik')}
                            className={styles.dropdownItem}>Brigádnik
                    </button>
                    <button type="button" onClick={() => selectRole('Manažer')}
                            className={styles.dropdownItem}>Manažer
                    </button>
                    <button type="button" onClick={() => selectRole('Obchod')} className={styles.dropdownItem}>Obchod
                    </button>
                    <button type="button" onClick={() => selectRole('Majiteľ')}
                            className={styles.dropdownItem}>Majiteľ
                    </button>
                </div>
            </div>

            <label className={styles['form-label']} htmlFor="iban">IBAN</label>
            <input
                id="iban"
                type="text"
                name="iban"
                value={newUserData.iban}
                onChange={handleChange}
                placeholder="Zadajte IBAN"
                pattern="SK\d{2}\s?[\dA-Z]{4}\s?[\dA-Z]{16}"
                className={styles['form-input']}
            />

            <button type="submit" className={styles['form-button']}>
                <FontAwesomeIcon icon={faSave}/> Uložiť
            </button>

        </form>
    );
};

export default AddUserForm;

