// src/components/shiftManagement/DateFilter.js

import React, {useState} from 'react';
import styles from './ShiftFilters.module.css';

const DateFilter = ({setFilter}) => {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedRange, setSelectedRange] = useState("týždeň");

    const rangeMappings = {
        "den": "deň",
        "tyzden": "týždeň",
        "mesiac": "mesiac",
        "rok": "rok"
    };

    const ascii = Object.keys(rangeMappings);

    // Inverse mapping for displaying the values
    const inverseRangeMappings = Object.fromEntries(
        Object.entries(rangeMappings).map(([key, value]) => [value, key])
    );

    const formattedDateRange = () => {
        console.log("xd1");
        let endDate;
        let rangeString = formattedDate(selectedDate); // Start with the start date

        switch (inverseRangeMappings[selectedRange]) {
            case "tyzden":
                endDate = new Date(selectedDate);
                endDate.setDate(endDate.getDate() + 6);
                rangeString += ` - ${formattedDate(endDate)}`;
                break;
            case "mesiac":
                endDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, selectedDate.getDate());
                endDate.setDate(endDate.getDate() - 1);
                rangeString += ` - ${formattedDate(endDate)}`;
                break;
            case "rok":
                endDate = new Date(selectedDate.getFullYear() + 1, selectedDate.getMonth(), selectedDate.getDate());
                endDate.setDate(endDate.getDate() - 1);
                rangeString += ` - ${formattedDate(endDate)}`;
                break;
            default:
                // No action for the default case as all expected cases are covered
                break;
        }

        return rangeString;
    };

    const formattedDate = (date) => {
        console.log("xd2");
        const options = { day: "numeric", month: "long" };
        return date.toLocaleDateString("sk-SK", options);
    };

    const calculateNewDate = (direction) => {
        console.log("xd3");
        let newDate = new Date(selectedDate);

        switch (inverseRangeMappings[selectedRange]) {
            case "den":
                newDate.setDate(newDate.getDate() + direction);
                break;
            case "tyzden":
                newDate.setDate(newDate.getDate() + (7 * direction));
                break;
            case "mesiac":
                newDate.setMonth(newDate.getMonth() + direction);
                break;
            case "rok":
                newDate.setFullYear(newDate.getFullYear() + direction);
                break;
            default:
                // If none of the above cases match, we don't change the date.
                break;
        }

        return newDate;
    };

    const handlePreviousRange = () => {
        console.log("xd4");
        const newDate = calculateNewDate(-1);
        setSelectedDate(newDate);

        // Determine the new dateRange value based on selectedRange
        const newDateRange = inverseRangeMappings[selectedRange];

        // Update filter state to include both newDate and newDateRange
        setFilter(prev => ({
            ...prev,
            date: newDate.toISOString().split('T')[0],
            dateRange: newDateRange // Update dateRange as well to trigger fetch
        }));
    };

    const handleNextRange = () => {
        console.log("xd5");
        const newDate = calculateNewDate(1);
        setSelectedDate(newDate);

        // Determine the new dateRange value based on selectedRange
        const newDateRange = inverseRangeMappings[selectedRange];

        console.log("newDateRange: ", newDateRange);
        // Update filter state to include both newDate and newDateRange
        setFilter(prev => ({
            ...prev,
            date: newDate.toISOString().split('T')[0],
            dateRange: newDateRange // Assuming your backend expects dateRange in this format
        }));
    };

    const updateSelectedRange = (newRange) => {
        console.log("xd6");
        const mappedRange = rangeMappings[newRange];
        setSelectedRange(mappedRange);

        setFilter(prev => ({
            ...prev,
            dateRange: newRange,
        }));
    };

    return (
        <label htmlFor="date" className={styles.filterLabel}>Datum
            <div className={styles.dateSelectionContainer}>
                <input
                    type="date"
                    className={styles.filterInput}
                    value={selectedDate.toISOString().split("T")[0]}
                    onChange={(e) => {
                        const newDate = new Date(e.target.value);
                        setSelectedDate(newDate);
                        setFilter(prev => ({
                            ...prev,
                            date: newDate.toISOString().split('T')[0], // Update the date in the filter state
                        }));
                    }}
                />
                <select
                    className={styles.filterSelect}
                    value={inverseRangeMappings[selectedRange]}
                    onChange={(e) => updateSelectedRange(e.target.value)}
                >
                    {ascii.map((rangeKey) => (
                        <option key={rangeKey} value={rangeKey}>{rangeMappings[rangeKey]}</option>
                    ))}
                </select>
                <div className={styles.rangeButtonContainer}>
                    <button className={styles.rangeButton} onClick={handleNextRange}>
                        <i className="fas fa-chevron-up"></i>
                    </button>
                    <button className={styles.rangeButton} onClick={handlePreviousRange}>
                        <i className="fas fa-chevron-down"></i>
                    </button>
                </div>

                <span>{formattedDateRange()}</span>
            </div></label>
    );
};

export default DateFilter;

