// src/components/account/Account.js

import React from 'react';
import NavigationBar from '../common/NavigationBar';
import Header from '../common/Header';
import styles from './Account.module.css'; // Ensure you have created this CSS module
import dashStyles from "../dashboard/Dashboard.module.css"; // Reuse dashboard container styles

const Account = () => {
    return (
        <div className={dashStyles.dashboardContainer}>
            <Header />
            <NavigationBar />
            <main className={styles.accountMain}>
                <h1>Účet</h1>
                <p>Tu môžete spravovať informácie o svojom účte.</p>
                {/* Add account management functionalities here */}
            </main>
        </div>
    );
}

export default Account;

