// src/components/settings/Settings.js

import React from 'react';
import NavigationBar from '../common/NavigationBar';
import Header from '../common/Header';
import styles from './Settings.module.css'; // Ensure you have created this CSS module
import dashStyles from "../dashboard/Dashboard.module.css"; // Reuse dashboard container styles

const Settings = () => {
    return (
        <div className={dashStyles.dashboardContainer}>
            <Header />
            <NavigationBar />
            <main className={styles.settingsMain}>
                <h1>Nastavenia</h1>
                <p>Tu môžete upravovať svoje nastavenia.</p>
                {/* Add settings functionalities here */}
            </main>
        </div>
    );
}

export default Settings;

