// src/components/dashboard/Dashboard.js

import React from 'react';
import NavigationBar from "../common/NavigationBar";
import Header from "../common/Header";
import styles from './Dashboard.module.css';

const Dashboard = ({ user }) => {
    return (
        <div className={styles.dashboardContainer}>
            <Header />
            <NavigationBar />
            <main className={styles.dashboardMain}>
                {/* Dynamic content will be rendered here */}
                {/* ... */}
            </main>
        </div>
    );
};

export default Dashboard;

