/* PrivacyPolicy.js */

import React from 'react';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
    return (
        <div className={styles.privacyPolicy}>
            <h1>Zásady ochrany osobných údajov</h1>
            <p>Dátum účinnosti: 20. máj 2024</p>
            <h2>Úvod</h2>
            <p>Vitajte v aplikácii DTPM, systéme na rezerváciu zmien určenom pre študentov na čiastočný úväzok v supermarketoch Klas v Košiciach, ktorý prevádzkuje pracovná agentúra DTPM. Tieto zásady ochrany osobných údajov vysvetľujú, ako zhromažďujeme, používame, zdieľame a chránime vaše údaje, keď používate našu aplikáciu. Vaše súkromie je pre nás dôležité a zaväzujeme sa chrániť vaše osobné údaje.</p>
            <h2>Aké údaje zhromažďujeme</h2>
            <h3>1. Osobné údaje</h3>
            <p>Pri registrácii a používaní našej aplikácie môžeme zhromažďovať nasledujúce osobné údaje:</p>
            <ul>
                <li>Meno a priezvisko</li>
                <li>Emailová adresa</li>
                <li>Telefónne číslo</li>
                <li>IBAN (na účely platby)</li>
                <li>Dátum, čas a miesto rezervovaných zmien</li>
            </ul>
            <h3>2. Dochádzkové listiny</h3>
            <p>Môžeme zhromažďovať fotografie dochádzkových listín, ktoré nahráte do našej aplikácie.</p>
            <h2>Ako používame vaše údaje</h2>
            <p>Zhromaždené údaje používame na rôzne účely, vrátane:</p>
            <ul>
                <li>Poskytovanie a udržiavanie našej služby</li>
                <li>Informovanie o zmenách našej služby</li>
                <li>Umožnenie účasti na interaktívnych funkciách našej služby</li>
                <li>Poskytovanie zákazníckej podpory</li>
                <li>Zhromažďovanie analýz alebo cenných informácií na zlepšenie našej služby</li>
                <li>Monitorovanie používania našej služby</li>
                <li>Zisťovanie, predchádzanie a riešenie technických problémov</li>
            </ul>
            <h2>Zdieľanie vašich údajov</h2>
            <p>Vaše osobné údaje môžeme zdieľať v nasledujúcich situáciách:</p>
            <ul>
                <li>S inými používateľmi: Informácie, ako je vaše meno a rezervované zmeny, môžu byť zdieľané s inými používateľmi (napr. manažérmi a kolegami) v rámci služby.</li>
                <li>S poskytovateľmi služieb tretích strán: Môžeme zamestnávať tretie strany a jednotlivcov na uľahčenie našej služby, poskytovanie služby v našom mene, vykonávanie servisných úloh alebo pomoc pri analýze používania našej služby.</li>
                <li>Z právnych dôvodov: Vaše osobné údaje môžeme zverejniť, ak to vyžaduje zákon alebo v reakcii na platné požiadavky verejných orgánov (napr. súd alebo vládna agentúra).</li>
            </ul>
            <h2>Bezpečnosť údajov</h2>
            <p>Bezpečnosť vašich osobných údajov berieme vážne a používame rôzne opatrenia na ich ochranu, vrátane:</p>
            <ul>
                <li>Šifrovanie údajov počas prenosu a ukladania</li>
                <li>Pravidelné aktualizácie a testovanie našich bezpečnostných opatrení</li>
                <li>Obmedzený prístup k osobným údajom iba na autorizovaný personál</li>
            </ul>
            <h2>Vaše práva na ochranu údajov</h2>
            <p>Máte určité práva na ochranu údajov, vrátane:</p>
            <ul>
                <li>Práva na prístup, aktualizáciu alebo vymazanie informácií, ktoré o vás máme</li>
                <li>Práva na opravu nesprávnych alebo neúplných informácií</li>
                <li>Práva namietať proti spracovaniu vašich osobných údajov</li>
                <li>Práva požadovať obmedzenie spracovania vašich osobných údajov</li>
                <li>Práva na prenosnosť údajov</li>
                <li>Práva kedykoľvek odvolať súhlas</li>
            </ul>
            <p>Ak chcete tieto práva uplatniť, kontaktujte nás na adrese <a href="mailto:molodchykr@gmail.com">molodchykr@gmail.com</a>.</p>
            <h2>Ochrana detí</h2>
            <p>Naša služba nie je určená osobám mladším ako 18 rokov. Vedome nezhromažďujeme osobné údaje od detí mladších ako 18 rokov. Ak zistíme, že sme zhromaždili osobné údaje od dieťaťa mladšieho ako 18 rokov bez overenia rodičovského súhlasu, podnikneme kroky na odstránenie týchto informácií z našich serverov.</p>
            <h2>Zmeny v týchto zásadách ochrany osobných údajov</h2>
            <p>Tieto zásady ochrany osobných údajov môžeme z času na čas aktualizovať. O všetkých zmenách vás budeme informovať zverejnením nových zásad ochrany osobných údajov na tejto stránke. Odporúčame vám, aby ste tieto zásady ochrany osobných údajov pravidelne kontrolovali kvôli prípadným zmenám. Zmeny týchto zásad ochrany osobných údajov sú účinné, keď sú zverejnené na tejto stránke.</p>
            <h2>Kontaktujte nás</h2>
            <p>Ak máte akékoľvek otázky týkajúce sa týchto zásad ochrany osobných údajov, kontaktujte nás na:</p>
            <ul>
                <li>Email: <a href="mailto:molodchykr@gmail.com">molodchykr@gmail.com</a></li>
            </ul>
            <p>Ďakujeme, že používate aplikáciu DTPM. Zaväzujeme sa chrániť vaše súkromie a zabezpečiť bezpečnosť vašich osobných údajov.</p>
        </div>
    );
};

export default PrivacyPolicy;



