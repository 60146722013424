// src/components/chat/Chat.js

import React from 'react';
import NavigationBar from '../common/NavigationBar'; // Ensure the import path is correct
import Header from '../common/Header';
import dashStyles from "../dashboard/Dashboard.module.css"; // Reuse dashboard container styles
import chatStyles from './Chat.module.css'; // Create or adjust styles specifically for chat

const Chat = () => {
    return (
        <div className={dashStyles.dashboardContainer}>
            <Header />
            <NavigationBar />
            <main className={chatStyles.chatMain}>
                <h1>Chatovacia miestnosť</h1> {/* Translated to Slovak */}
                <p>Vitajte v chatovacej miestnosti! Zapojte sa do diskusií.</p> {/* Translated to Slovak */}
                {/* Add chat functionalities here */}
            </main>
        </div>
    );
}

export default Chat;

